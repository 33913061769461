@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .div-text {
    mask-image: linear-gradient(to bottom, black calc(100% - 60px), transparent 100%);
  }
}

body {
  margin: 0;
  font-family: 'Source Sans 3', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

._pendo-badge {
  bottom: 24px !important;
  right: 24px !important;
  left: auto !important;
  z-index: 10 !important;
  background: white !important;
}
._pendo-step-container {
  right: 24px !important;
  left: auto !important;
}
._pendo-badge {
  display: none !important;
}
@media (min-width: 640px){
  ._pendo-badge {
    bottom: 24px !important;
  }
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/* active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #1E24AE;
  opacity: 1;
  border-radius: 25px;
  height:6px;
  width: 1.5rem;
}

/* non-active bullets */
span.swiper-pagination-bullet {
  background-color: #EAEBF5;
  opacity: 1;
  border-radius: 25px;
  height:6px;
  width: 1.5rem;
}

@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon/icomoon.eot?3h5nze');
  src:  url('./assets/fonts/icomoon/icomoon.eot?3h5nze#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon/icomoon.ttf?3h5nze') format('truetype'),
    url('./assets/fonts/icomoon/icomoon.woff?3h5nze') format('woff'),
    url('./assets/fonts/icomoon/icomoon.svg?3h5nze#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-car:before {
  content: "\e910";
}
.icon-tag:before {
  content: "\e90f";
}
.icon-close:before {
  content: "\e90e";
}
.icon-cancel-square:before {
  content: "\e932";
}
.icon-chat:before {
  content: "\e933";
}
.icon-circle-filled:before {
  content: "\e934";
}
.icon-error:before {
  content: "\e935";
}
.icon-info-filled:before {
  content: "\e936";
}
.icon-polygon-filled:before {
  content: "\e937";
}
.icon-square-filled:before {
  content: "\e938";
}
.icon-wifi-offline:before {
  content: "\e939";
}
.icon-logout:before {
  content: "\e93a";
}
.icon-square-outline:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-arrow-up:before {
  content: "\e905";
}
.icon-bell:before {
  content: "\e906";
}
.icon-book-ride:before {
  content: "\e907";
}
.icon-calendar:before {
  content: "\e908";
}
.icon-card:before {
  content: "\e909";
}
.icon-chev-down:before {
  content: "\e90a";
}
.icon-chev-left:before {
  content: "\e90b";
}
.icon-chev-right:before {
  content: "\e90c";
}
.icon-chev-up:before {
  content: "\e90d";
}
.icon-error1:before {
  content: "\e912";
}
.icon-gift:before {
  content: "\e913";
}
.icon-hide:before {
  content: "\e914";
}
.icon-home:before {
  content: "\e915";
}
.icon-infinity:before {
  content: "\e916";
}
.icon-info:before {
  content: "\e917";
}
.icon-key:before {
  content: "\e918";
}
.icon-mail:before {
  content: "\e919";
}
.icon-mic:before {
  content: "\e91a";
}
.icon-more:before {
  content: "\e91b";
}
.icon-oneway:before {
  content: "\e91c";
}
.icon-pen:before {
  content: "\e91d";
}
.icon-percentage:before {
  content: "\e91e";
}
.icon-phone:before {
  content: "\e91f";
}
.icon-plus .path1:before {
  content: "\e920";
  color: rgb(0, 0, 0);
}
.icon-plus .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(11, 22, 38);
}
.icon-plus .path3:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-plus .path4:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(11, 22, 38);
}
.icon-profile:before {
  content: "\e924";
}
.icon-question:before {
  content: "\e925";
}
.icon-repeat:before {
  content: "\e926";
}
.icon-return:before {
  content: "\e927";
}
.icon-schedule-ride:before {
  content: "\e928";
}
.icon-search:before {
  content: "\e929";
}
.icon-show:before {
  content: "\e92a";
}
.icon-success:before {
  content: "\e92b";
}
.icon-tick:before {
  content: "\e92d";
}
.icon-time:before {
  content: "\e92e";
}
.icon-trash:before {
  content: "\e92f";
}
.icon-warning:before {
  content: "\e930";
}
.icon-zap:before {
  content: "\e931";
}
